import React from 'react';
import { graphql } from 'gatsby'
import styled from 'styled-components'
import tw from 'twin.macro'
import SEO from '../components/SEO/seo'
import SliceZone from '../components/SliceZone'

const Root = styled.div`
    ${tw`sm:mx-2 md:mx-12 sm:flex justify-center`}
`;

export default ({ data }) => {
  if (!data) return null
  const doc = data.prismic.allWines_pages.edges[0]
  const slices = doc.node.body
  return (
    <>
      <SEO title='Wines' />
      <Root>
        <SliceZone slices={slices} />
      </Root>
    </>
  )
}

export const query = graphql`
query allWinesQuery($lang: String) {
  prismic {
    allWines_pages(lang: $lang) {
      edges {
        node {
          _meta {
            lang
            type
            uid
            alternateLanguages {
              lang
              type
              uid
            }
          }
          body {
            ... on PRISMIC_Wines_pageBodyImage_link {
              type
              label
              primary {
                text
                image
                link {
                  _linkType
                  ... on PRISMIC_Wine {
                    _linkType
                    _meta {
                      lang
                      type
                      uid
                      alternateLanguages {
                        lang
                        type
                        uid
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`;
